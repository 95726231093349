.tab-container-wrapper {
  @apply mt-6;
}

.tab-container {
  @apply flex gap-1;
  .ant-tabs-nav {
    @apply mb-0
  }
}
.tab-item {
  @apply flex bg-[#F0F0F0]  px-4 py-2.5 rounded-t-lg ;

  &-active {
    @apply bg-white text-primary-green;
  }
}
.tab-content {
  @apply bg-white h-full p-6 rounded-b-lg;
}

.tab-link {
  font-size: theme("fontSize.sm");

}
.tab-link-underline-active {
  font-size: theme("fontSize.sm");
  font-weight: 700;
  position: relative;
  color: theme("colors.primary-green");

  &::after {
    content: "";
    position: absolute;
    left:0;
    bottom: calc(-.75rem - 2px);
    width: 100%;
    background: theme("colors.primary-green");
    height: 2px;
  }
}
