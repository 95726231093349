@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  interpolate-size: allow-keywords;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;

  // enable on all pages except the login page
  &:not(:has(.login-page)) {
      background: #F5F5F5;
  }

  //do not allow scroll or any click events on the body if the modal-overlay is open
  &:has(.modal-overlay) {
      overflow: hidden;
      pointer-events: none;
    & .modal-overlay {
      pointer-events: auto;
    }
  }
}

//html, body {
//  width: 100%;
//  height: 100%;
//}
