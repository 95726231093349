.markdown-body {
  ol {
    list-style: decimal;
  }

}
.markdownContent table {
  table-layout: fixed;
  padding-block: 2rem;
  max-width: 46rem;
  //width: 46rem;
  display: block;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  tr, td, tbody{
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: collapse;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}


